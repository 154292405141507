import cloneDeep from 'clone-deep';
import { ISbRichtext } from '@storyblok/js';

interface Props {
  attrsCopy?: ISbRichtext['attrs'];
  processed: boolean;
}

export default defineNuxtPlugin(() => {
  const rteSchema = cloneDeep(RichTextSchema);

  // @ts-ignore
  rteSchema.nodes.paragraph = function (node: ISbRichtext) {
    const props: Props = { attrsCopy: {}, processed: false };
    let attrs = {};

    if (props.processed) {
      attrs = props.attrsCopy;
    }

    if (
      node.content &&
      node.content.length === 1 &&
      node.content[0].marks &&
      node.content[0].marks.length === 1 &&
      node.content[0].marks[0].type === 'styled'
    ) {
      attrs = node.content[0].marks[0].attrs;
      props.attrsCopy = attrs;
      props.processed = true;

      delete node.content[0].marks;
    }

    return {
      tag: [
        {
          attrs,
          tag: 'p',
        },
      ],
    };
  };

  // @ts-ignore
  rteSchema.nodes.heading = function (node: ISbRichtext) {
    const props: Props = { attrsCopy: {}, processed: false };
    let attrs = {};

    if (props.processed) {
      attrs = props.attrsCopy;
    }

    if (
      node.content &&
      node.content.length === 1 &&
      node.content[0].marks &&
      node.content[0].marks.length === 1 &&
      node.content[0].marks[0].type === 'styled'
    ) {
      attrs = node.content[0].marks[0].attrs;
      props.attrsCopy = attrs;
      props.processed = true;

      delete node.content[0].marks;
    }

    return {
      tag: [
        {
          attrs,
          tag: `h${node.attrs.level}`,
        },
      ],
    };
  };

  // @ts-ignore
  rteSchema.nodes.list_item = function (node: ISbRichtext) {
    const props: Props = { attrsCopy: {}, processed: false };
    let attrs = {};

    if (props.processed) {
      attrs = props.attrsCopy;
    }

    if (
      node.content &&
      node.content.length &&
      node.content[0].content &&
      node.content[0].content.length &&
      node.content[0].content[0].marks &&
      node.content[0].content[0].marks.length &&
      node.content[0].content[0].marks[0] &&
      node.content[0].content[0].marks[0].attrs &&
      node.content[0].content[0].marks[0].attrs.class
    ) {
      attrs = node.content[0].content[0].marks[0].attrs;
      props.attrsCopy = attrs;
      props.processed = true;

      const content = node.content[0].content;
      node.content = [{ text: content[0].text, type: 'text' }];
    } else if (
      node.content &&
      node.content.length === 1 &&
      node.content[0].type === 'paragraph'
    ) {
      const content = node.content[0].content;
      node.content = content;
    }

    return {
      tag: [
        {
          attrs,
          tag: 'li',
        },
      ],
    };
  };

  return {
    provide: {
      rteSchema,
    },
  };
});
