import { default as _91_46_46_46slug_93OMFZQEfw61Meta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/[...slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93OMFZQEfw61Meta?.name ?? "slug",
    path: _91_46_46_46slug_93OMFZQEfw61Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93OMFZQEfw61Meta || {},
    alias: _91_46_46_46slug_93OMFZQEfw61Meta?.alias || [],
    redirect: _91_46_46_46slug_93OMFZQEfw61Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/[...slug].vue").then(m => m.default || m)
  }
]