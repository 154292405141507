// Usage: if ($noMotionPreference === true) {
export default defineNuxtPlugin(() => {
  return {
    provide: {
      noMotionPreference: window.matchMedia(
        '(prefers-reduced-motion: no-preference)'
      ).matches,
    },
  };
});
